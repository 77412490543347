<template>
  <section class="main">
    <header>
      <HomeHeader />
    </header>
    <section class="block">
      <div class="d-flex flex-column container pb-5">
        <h1>Специальное предложение</h1>
        <Carousel goods :products="products" />
        <router-link class="btnh m-auto d-flex align-items-center mt-2" to="/catalog"
          >перейти в каталог<img src="@/assets/img/arrow.png" alt=""
        /></router-link>
      </div>
    </section>
    <!-- <Carusel reviews-carusel /> -->
    <Carousel :reviews="reviews" />
    <HomeFooter />
  </section>
</template>

<script>
import HomeHeader from "@/components/home/HomeHeader.vue";
import HomeFooter from "@/components/home/HomeFooter.vue";
import Carousel from "@/components/ui/Carousel.vue";

export default {
  data() {
    return {
      products: [],
      reviews: [],
    };
  },
  components: {
    HomeHeader,
    HomeFooter,
    Carousel,
  },
  methods: {
    async getSpecialPropose() {
      const featureProd = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/featured"
      );
      const { data } = featureProd.data;
      this.products = await data[0].products;
    },
    async getAllReviews() {
      const reviewsResp = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/review"
      );
      const { reviews } = reviewsResp.data.data;
      this.reviews = await reviews;
      // console.log(reviews);
    },
  },
  created() {
    // Products for slider
    this.getSpecialPropose();
    // Reviews for slider
    this.getAllReviews();
  },
};
</script>

<style>
header {
  width: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #2b2b2b;
}

.block h1 {
  margin-top: 80px;
  margin-bottom: 55px;
}

.btnh {
  padding-left: 33px;
  padding-right: 13px;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 17.3614px;
  line-height: 21px;
  color: #7a7a7a;
}
.btnh:hover {
  color: #000000;
}
.btnh img {
  padding: 23px;
}

.btnh {
  background: #ffffff;
  border: 1.57831px solid #75b051;
  box-sizing: border-box;
  color: #7a7a7a;
}

.hooper-list {
  padding: 0 5px;
}

@media (max-width: 425px) {
  .block h1 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 25px;
    line-height: 32px;
  }
}
</style>
