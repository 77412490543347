<template>
  <section class="block block4">
    <div
      class="d-flex flex-column justify-content-center align-items-center insta"
    >
      <a href="https://www.instagram.com/rizaherb.kz">
        <img src="@/assets/img/inst.svg" alt="" />
      </a>
      <h2>
        подпишитесь на наш аккаунт в instagram,<br />
        чтобы следить за нашими новостями
      </h2>
    </div>
    <InstgramBlock :banners="instImages" />
    <div
      class="d-flex container fut flex-column flex-lg-row mb-4 justify-content-around"
    >
      <img
        src="@/assets/img/futpic.png"
        class="futpic d-flex mt-2"
        alt=""
        @click="productDescription"
      />
      <div class="form px-5 py-4 mt-2">
        <h3>Узнавайте первыми</h3>
        <h4>
          Подпишитесь на новостную рассылку с самыми интересными новостями и
          акциями
        </h4>
        <form class="d-flex flex-column align-items-stretch">
          <input
            class="mt-5 mb-3 p-3"
            type="text"
            name="name"
            placeholder="Имя"
          />
          <input
            class="mb-5 p-3"
            type="text"
            name="phone"
            placeholder="E-Mail"
          />
          <button class="btn py-3" type="submit">Подписаться</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      instImages: [],
    };
  },
  components: {
    InstgramBlock: () => import("@/components/home/InstgramBlock.vue"),
  },
  methods: {
    productDescription() {
      this.$router.replace("/products");
    },
    async instagramBannerLoader() {
      const Inst = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/banners&id=10"
      );
      const {
        data: { data },
      } = Inst;
      this.instImages = data;
    },
    refrence() {
      window.location.href = "https://www.instagram.com/"
    }
  },
  created() {
    this.instagramBannerLoader();
  },
};
</script>

<style scoped>
.btn {
  background: #75b051;
  color: #ffffff;
  border-radius: 0;
}
.btn:hover {
  background: #ffffff;
  border: 1.57831px solid #75b051;
  box-sizing: border-box;
  color: #7a7a7a;
}

.block4 {
  margin-top: 20px;
}
.block4 h2 {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  color: #464646;
}

.block4 .form {
  background: #f4f4f4;
  max-width: 350px;
}
.block4 .form input {
  background: #ffffff;
  border-radius: 5px;
  border: none;
  width: 100%;
}
.block4 .form .btn {
  border-radius: 5px;
  max-width: 170px;
  width: 100%;
}
.block4 .fut h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0;
}
.block4 .fut h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
}

.block4 img {
  cursor: pointer;
}
.futpic {
  max-width: 100%;
}

@media (max-width: 768px) {
  .block4 {
    margin-top: 5px;
  }
  .block4 h2 {
    font-size: 16px;
    padding: 0 10px;
  }
}

@media (max-width: 375px) {
  .block4 h2 {
    font-size: 12px;
  }
}
</style>
