<template>
  <div class="home-banner">
    <div class="home-banner__wrapper">
      <div class="home-banner__item">
        <BannerItem :item="banners[0]" />
        <BannerItem :item="banners[1]" />
      </div>
      <div class="home-banner__item">
        <BannerItem :item="banners[2]" />
      </div>
      <div class="home-banner__item">
        <BannerItem :item="banners[3]" />
        <BannerItem :item="banners[4]" />
      </div>
      <div class="home-banner__item">
        <BannerItem :item="banners[5]" />
        <BannerItem :item="banners[6]" />
      </div>
      <div class="mobile-banner">
        <img :src="banner" alt="banner.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BannerItem: () => import("@/components/home/BannerItem.vue"),
  },
  data() {
    return {
      arrImages: [],
      banner: ''
    };
  },
  computed: {
    banners() {
      if (this.arrImages && this.arrImages.length) {
        return this.arrImages;
      }
      return [];
    },
  },
  methods: {
    async mainBannerLoader() {
      const banner = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/banners&id=9"
      );
      const {
        data: { data },
      } = banner;
      // console.log(data);
      this.arrImages = data;
    },

    async getMobileBanner() {
      const banner = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/banners&id=6"
      );
      const { image } = banner.data.data[0];
      this.banner = image;
    },
  },
  created() {
    this.mainBannerLoader();
    this.getMobileBanner();
  },
};
</script>

<style scoped>
div {
  flex-wrap: nowrap;
}
.home-banner {
  display: flex;
  justify-content: center;
}
.home-banner__wrapper {
  display: flex;
  width: 100%;
  max-width: 1440px;
}

.home-banner__item {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
}

.mobile-banner {
  display: none;
}

.mobile-banner img {
  width: 100%;
  max-width: 1440px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .home-banner__item {
    display: none;
  }
  .mobile-banner {
    display: block;
  }

}
</style>
